@use 'variables';
@use 'theme';
@use 'typography';
@use 'mat-overrides';

@import 'bootstrap/scss/bootstrap-grid';
@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/mixins/grid";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Titillium Web", "Helvetica Neue", sans-serif;
  background-color: variables.$color-background;
}

a {
  color: variables.$color-primary;
  text-decoration: underline;

  &:hover, &:active {
    color: variables.$color-primary-dark;
    text-decoration: underline;
  }
}

qrcode .qrcode svg {
  max-width: 100%;
  max-height: 100%;
}

.line-breaks {
  white-space: pre-line;
}

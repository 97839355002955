@use "variables";

@mixin font-regular() {
  font-family: variables.$font-regular;
  font-weight: variables.$font-weight-regular;
}

@mixin font-bold() {
  font-family: variables.$font-bold;
  font-weight: variables.$font-weight-bold;
}

@mixin font-title() {
  font-family: variables.$font-title;
  font-weight: variables.$font-weight-title;
}

@mixin font-subtitle() {
  font-family: variables.$font-subtitle;
  font-weight: variables.$font-weight-subtitle;
}

@mixin title-typography($font-size, $margin-top, $margin-bottom) {
  font-size: $font-size;
  margin-top: $margin-top;
  margin-bottom: $margin-bottom;
}

@mixin no-margin-first-last() {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@use "variables";
@use "mixins";

// region General
body {
  line-height: variables.$line-height-regular;
  font-size: variables.$font-size-regular;
}

p {
  @include mixins.no-margin-first-last;
}
// endregion

// region Headings
h1, h2, h3, h4, h5, h6 {
  @include mixins.font-title;
  @include mixins.no-margin-first-last;
  line-height: variables.$line-height-title;

  & + h1, & + h2, & + h3, & + h4, & + h5, & + h6 {
    margin-top: variables.$font-margin-top-content;
  }
}

.mat-h2, .mat-title, .mat-typography {
  & h1 {
    @include mixins.title-typography(variables.$font-size-h1, variables.$font-margin-top-h1, variables.$font-margin-bottom-h1);
  }

  & h2 {
    @include mixins.title-typography(variables.$font-size-h2, variables.$font-margin-top-h2-h3-h4, variables.$font-margin-bottom-h2-h3-h4);
  }

  & h3 {
    @include mixins.title-typography(variables.$font-size-h3, variables.$font-margin-top-h2-h3-h4, variables.$font-margin-bottom-h2-h3-h4);
  }

  & h4 {
    @include mixins.title-typography(variables.$font-size-h4, variables.$font-margin-top-h2-h3-h4, variables.$font-margin-bottom-h2-h3-h4);
  }
}
// endregion

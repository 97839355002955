@use "variables";

.mat-mdc-list-base {
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 1);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 1);
}

.addon-chip {
  mat-icon {
    height: 18px;
    font-size: 18px;
  }
}

.mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
  opacity: 0 !important;
}

.mat-button-toggle-checked[aria-expanded="true"] {
  background-color: darken($color: variables.$color-primary, $amount: 6) !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%),
    inset 0px 1px 5px 0px rgb(0 0 0 / 12%)
      !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 !important;
  line-height: 36px !important;
}

/* Material styles for toggled save button */
.save-button-container.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border: none;
}

.save.mat-button-toggle {
  background-color: variables.$color-primary;
  color: white;

  box-sizing: border-box;

  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 32px;
  line-height: 36px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  font-family: "Titillium Web", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;

  overflow: hidden;

  outline: none !important;

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  padding: 0 16px;

  + .save {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    padding: 0 8px;
  }

  &:focus,
  &:hover {
    background-color: variables.$color-primary !important;
  }

  &.disabled {
    background-color: variables.$color-darkgrey !important;
    cursor: default !important;
  }
}

mat-card.mat-mdc-card {
  box-shadow: none;
  border-radius: 8px;

  .mat-mdc-card-header {
    padding: 24px 24px 0;
  }

  .mat-mdc-card-content {
    padding: 0 24px;
  }

  .mat-mdc-card-content:last-child {
    padding-bottom: 24px;
  }

}

.mat-mdc-raised-button {
  --mdc-protected-button-container-shape: 8px;
  box-shadow: none !important;

  &.mat-primary, &:not(:disabled) {
    --mdc-protected-button-label-text-color: #{variables.$color-light};
  }
}

.mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 48px;
  font-size: 1rem;
  font-weight: 400;
}
